<template>
  <b-jumbotron>

    <b-row cols=2>
 <h3 class="col-12">Imprimer Contrats</h3>
 <hr class="col-12">
      <b-col >
        <b-button href="/pdf/cdi.pdf"  target="_blank"  pill block variant="success">CDI</b-button>
      </b-col>
      <b-col>
        <b-button  href="/pdf/cdd.pdf"  target="_blank" variant="primary"  pill block>CDD</b-button>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col lg="12">
        <!--<b-form-radio-group
          id="btn-radios-3"
          v-model="selected"
          :options="options"
          buttons
          stacked
          button-variant="outline-primary"
        ></b-form-radio-group>-->
        <b-form-group label="Nom de l'employee">
          <b-form-select size="lg" v-model="selected" @change="getEmpData" :options="employees"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <br />
    <hr />
    <b-row cols-lg="5" cols="1">
      <b-col class="my-1">
        <b-button
          block
          pill
          variant="primary"
          v-b-modal.changePassword_Modal
          :disabled="selected=='' || statut == 'updating'  || statut == 'adding'"
        >Changer Mot de Passe</b-button>
      </b-col>
      <b-col class="my-1">
        <b-button
          block
          pill
          variant="info"
          v-b-modal.changePoste_modal
          :disabled="selected=='' || statut == 'updating'  || statut == 'adding'"
        >Changer poste</b-button>
      </b-col>
      <b-col class="my-1">
        <b-button
          block
          pill
          variant="danger"
          @click="delete_button"
          :disabled="selected=='' || statut == 'updating' || statut == 'adding'"
        >Supprimer</b-button>
      </b-col>
      <b-col class="my-1">
        <b-button
          block
          pill
          variant="warning"
          @click="update_button(statut)"
          :disabled="selected=='' "
        >{{statut=='updating'? 'Valider Modification' : 'Modifier'}}</b-button>
      </b-col>
      <b-col class="my-1 mb-2">
        <b-button
          block
          pill
          :disabled="statut == 'updating'"
          variant="success"
          @click="add_button(statut)"
        >{{statut=='adding'? 'Valider Ajout' : 'Ajouter'}}</b-button>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-alert
        show
        variant="success"
        class="pb-4 col-12"
        style="text-align:center"
      >Informations personnelles</b-alert>
      <b-col lg="5" sm="12">
        <b-form-group label="Nom Complet">
          <b-input
            :disabled="statut=='consulting'"
            v-model="employee.Nom_complet"
            placeholder="le nom de l'employee"
            class="col-12"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col lg="5" sm="12">
        <b-form-group label="Poste Occupé">
          <b-input
            disabled
            :value="employee.fonction +' pour le departement ' + employee.departement "
            placeholder="Poste Occupé"
            class="col-12"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col lg="2" sm="12">
        <b-form-group label="Numero d'ordre">
          <b-input
            :disabled="statut=='consulting'"
            type="number"
            step="1"
            min="1"
            v-model="employee.id_emp_ci"
            placeholder="0"
            class="col-12"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="12">
        <b-form-group label="CNI">
          <b-input
            :disabled="statut=='consulting'"
            v-model="employee.cni"
            placeholder="X------"
            class="col-12"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="12">
        <b-form-group label="Affiliation CNSS">
          <b-input
            :disabled="statut=='consulting'"
            v-model="employee.afcnss"
            placeholder="-------"
            class="col-12"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="12">
        <b-form-group label="Imatriculation CNSS">
          <b-input
            :disabled="statut=='consulting'"
            v-model="employee.imcnss"
            placeholder="-------"
            class="col-12"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="12">
        <b-form-group label="Date debut de service">
          <b-form-datepicker :disabled="statut=='consulting'" v-model="employee.debute_date"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="12">
        <b-form-group label="Date de naissance">
          <b-form-datepicker :disabled="statut=='consulting'" v-model="employee.date_naiss"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="12">
        <b-form-group label="Type">
          <b-form-select
            :disabled="statut=='consulting'"
            v-model="employee.act"
            :options="[{value:'on', text : 'actif'},{value:'off', text : 'inactif'}]"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-alert
        show
        variant="success"
        class="pb-4 col-12"
        style="text-align:center"
      >Coordonnées de contact</b-alert>
      <b-col lg="4" sm="12">
        <b-form-group label="GSM">
          <b-input
            :disabled="statut=='consulting'"
            type="tel"
            v-model="employee.tel"
            placeholder="06XXXXXXXX"
            class="col-12"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col lg="8" sm="12">
        <b-form-group label="Adresse">
          <b-form-textarea
            :disabled="statut=='consulting'"
            v-model="employee.adresse"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal id="changePassword_Modal" title="Changer Mot de Passe de l'employee">
      <b-row cols="1">
        <b-col>
          <b-form-group label="Nom Complet">
            <b-input disabled v-model="employee.Nom_complet" class="col-12"></b-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nouveau Mot de passe">
            <b-input  max="4" v-model="employee.password" class="col-12"></b-input>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{  cancel}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="success" @click="update_password()">Modifier Mot de Passe</b-button>
        <b-button variant="danger" @click="cancel()">Annuler</b-button>
      </template>
    </b-modal>
    <b-modal id="changePoste_modal" title="Changer Poste de l'employee">
      <b-row cols="1">
        <b-col>
          <b-form-group label="Nom Complet">
            <b-input disabled v-model="employee.Nom_complet" class="col-12"></b-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Fonction">
            <b-form-select
              v-model="employee.fonction"
              class="col-12"
              :options="['Inconnu','Caissier','Serveur','Chargée de service','Autre']"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Departement">
            <b-form-select
              class="col-12"
              v-model="employee.departement"
              :options="['Inconnu','Hammam','Piscine non-couverte','Piscine Couverte','Snack','Cafe','superette','Parking']"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{  cancel}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="success" @click="update_poste()">Modifier Poste</b-button>
        <b-button variant="danger" @click="cancel()">Annuler</b-button>
      </template>
    </b-modal>
  </b-jumbotron>
</template>

<script>
export default {
  name: "HR",
  data() {
    return {
      statut: "consulting",
      selected: "",
      employee: {},
      employees: [],
    };
  },
  methods: {
    get_emps() {
      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/employees/get_employees",
          {},
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(async (response) => {
          const data = response.data;
          this.employees = data.map((el) => {
            return {
              value: el.id_emp,
              text: el.id_emp_ci + " - " + el.Nom_complet,
            };
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },
    getEmpData() {
      const axios = require("axios");
      this.statut = "consulting";
      axios
        .post(
          this.$store.state.api_url + "/employees/get_employee_data",
          {
            id_emp: this.selected,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(async (response) => {
          var data = response.data;
          if (data.id_emp == null) data.id_emp = this.selected;
          this.employee = data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },
    update_button(statut) {
      switch (statut) {
        case "consulting": {
          this.statut = "updating";
          break;
        }
        case "updating": {
          this.statut = "consulting";
          if (
            this.employee.Nom_complet &&
            this.employee.Nom_complet.trim() != "" &&
            this.employee.id_emp_ci &&
            this.id_emp_ci != 0
          ) {
            const axios = require("axios");
            axios
              .post(
                this.$store.state.api_url + "/employees/update_employee",
                this.employee,
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                this.$bvToast.toast("Modifié avec succée", {
                  title: `Succée`,
                  variant: "success",
                  solid: true,
                });
                this.get_emps();
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  console.log("uNauthorized request");
                  console.log(err);
                  this.error();
                } else {
                  console.log("error : " + err);
                  this.$bvToast.toast("Erreur", {
                    variant: "danger",
                    solid: true,
                  });
                }
              });
          } else {
            this.$bvToast.toast("Nom ou/et identifiant  Invalide(s)", {
              variant: "danger",
              solid: true,
            });
          }
          break;
        }
      }
    },
    delete_button() {
      this.$bvModal
        .msgBoxConfirm("Merci de confirmer la suppression de l'employee", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "OUI",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const axios = require("axios");
            axios
              .post(
                this.$store.state.api_url + "/employees/delete_employee",
                {
                  id_emp: this.selected,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                this.$bvToast.toast("Supprimé avec succée", {
                  title: `Succée`,
                  variant: "success",
                  solid: true,
                });
                this.selected = "";
                this.employee = new Object();
                this.get_emps();
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  console.log("uNauthorized request");
                  console.log(err);
                  this.error();
                } else {
                  console.log("error : " + err);
                  this.$bvToast.toast("Erreur", {
                    variant: "danger",
                    solid: true,
                  });
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    update_password() {
      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/employees/update_password",
          this.employee,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$bvToast.toast("Mot de Passe Changé ", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
          this.$root.$emit("bv::hide::modal", "changePassword_Modal");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    update_poste() {
      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/employees/change_poste",
          this.employee,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$bvToast.toast("Poste Changé", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
          this.getEmpData();
          this.$root.$emit("bv::hide::modal", "changePoste_modal");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    add_button(statut) {
      switch (statut) {
        case "consulting": {
          this.statut = "adding";
          this.selected = "";
          this.employee = new Object();
          this.employee.id_emp_ci = this.employees.length + 1;
          break;
        }
        case "adding": {
          this.statut = "consulting";
          if (
            this.employee.Nom_complet &&
            this.employee.Nom_complet.trim() != "" &&
            this.employee.id_emp_ci &&
            this.id_emp_ci != 0
          ) {
            const axios = require("axios");
            axios
              .post(
                this.$store.state.api_url + "/employees/add_employee",
                this.employee,
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                this.$bvToast.toast("Ajouté avec succée", {
                  title: `Succée`,
                  variant: "success",
                  solid: true,
                });
                this.get_emps();
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  console.log("uNauthorized request");
                  console.log(err);
                  this.error();
                } else {
                  console.log("error : " + err);
                  this.$bvToast.toast("Erreur", {
                    variant: "danger",
                    solid: true,
                  });
                }
              });
          } else {
            this.$bvToast.toast("Nom ou/et identifiant  Invalide(s)", {
              variant: "danger",
              solid: true,
            });
          }
          break;
        }
      }
    },
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
  mounted() {
    this.get_emps();
  },
};
</script>