import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const devUrl = 'http://localhost:5555';

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: "",
    api_url: process.env.NODE_ENV === 'production'
    ? "/api/noff"
    :  devUrl + "/noff"

  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    login: async ({ commit }, user) => {
      commit("auth_request");
      //console.log(user);
      try {
        const resp = await axios.post(  process.env.NODE_ENV === 'production'
        ? '/api/user/login'
        : devUrl + "/user/login", {}, {
          auth: {
            username: user.login,
            password: user.password
          }
        });
        const data = resp.data;
        const token = data.token
        const username = user.login
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] =  'Bearer ' +token;
        commit('auth_success', token, username);
        return resp.status;
      } catch (error) {
        commit('auth_error')
        localStorage.removeItem('token')
        throw error;
      }


    },
    async logout({ commit }) {
      commit('logout')
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']

    }


  },
  modules: {
  }
  , getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    
  }
});
