<template>
  <div id="elements" style="width:100%">
    <div id="research">
      <b-row>
        <b-col cols="12" lg="3">
          <label for="ldate">Date de debut</label>
          <input type="week" class="form-control" v-model="from_date" />
          <label for="ldate">Nom Complet</label>
          <v-select  v-model="employee" :options="employees" :reduce="employee => employee.Nom_complet" label="showname">
                        <div slot="no-options">désolé, aucune option correspondante</div>
          </v-select>
        </b-col>
        <b-col cols="12" lg="3">
          <!--<b-form-datalist id="motifs-list" :options="motifs"></b-form-datalist>-->
          <label>.</label>
          <div>
            <b-button block variant="success" @click="search">Rechercher</b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="3">
          <label for="ldate">Impression</label>
          <div>
            <b-button block variant="warning" @click="print_fiche">Fiche de Paie</b-button>
          </div>
          <label>.</label>
          <div>
            <b-button block variant="info" v-b-modal.ModalCnss>Resumé Mensuel</b-button>
          </div>
        </b-col>
      </b-row>
      <br />
    </div>
    <div>
      <b-jumbotron>
        <b-row>
          <b-col>
            <b-alert show variant="success" class="pb-4" style="text-align:center">
              Registre des salaires
              <b-button style="float:right" variant="primary" @click="prompt_Add()">Ajouter</b-button>
              <div class="mt-3">
                <b-form-group  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group button-variant="outline-primary" id="btn-radios-1" v-model="selected_mode" :options="salary_mode"
                    :aria-describedby="ariaDescribedby" name="radios-btn-default" buttons></b-form-radio-group>
                </b-form-group>
              </div>
            </b-alert>
            <b-table
              head-variant="dark"
              foot-variant="null"
              :fields="fields"
              primary-key="id_emp_ci"
              :items="payments"
              sortBy="id_emp_ci"
              hover
              responsive
            >
              <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
              <template v-slot:cell(somme)="data">{{ (data.item.taux * data.item.total_h).toFixed(2) }}</template>
              <template v-slot:cell(date_pay)="data">{{ format_date(data.value) }}</template>
              <template v-slot:cell(actions)="row">
                <b-dropdown variant="warning" id="dropdown-buttons" text="Options" class="m-2">
                  <b-dropdown-item-button
                    @click="show_prompt_update(row.item, row.index, $event.target)">Modifier</b-dropdown-item-button>
                  <b-dropdown-item-button @click="show_prompt_delete(row)">Suprimer</b-dropdown-item-button>
                </b-dropdown>
              </template>
              <template v-slot:custom-foot>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th variant="success" colspan="2">Total</b-th>
                  <b-th variant="success" colspan="2">{{ total.toFixed(2) }}</b-th>
                </b-tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-jumbotron>
    </div>
    <img id="logo" src="@/assets/logo.jpg" style="display:none" />
    <b-modal :id="infoModal.id" :title="infoModal.type" header-text-variant="dark"
      :header-bg-variant="infoModal.type != 'Ajouter' ? 'warning' : 'success'" @hide="resetInfoModal">
      <b-container fluid>
        <b-row class="mb-2">
          <b-col cols="3">employe</b-col>
          <b-col>

            <v-select v-model="infoModal.content.Nom_emp" :options="employees" :reduce="employee => employee.Nom_complet"
              label="showname" taggable
              :create-option="nom => ({ Nom_complet: nom, index: employees[employees.length - 1].id_emp + 1, showname: '[Nouvel employé va etre créé] ' + nom })"></v-select>

          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">T. Jours</b-col>
          <b-col>
            <b-input type="number" min="0" max="7" step="0.5" v-model.number="infoModal.content.total_h" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Taux</b-col>
          <b-col>
            <b-input type="number" v-model.number="infoModal.content.taux" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Date</b-col>
          <b-col>
            <input class="form-control" type="week" v-model="infoModal.content.date_pay" />
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button :variant="infoModal.type != 'Ajouter' ? 'warning' : 'success'" @click="callFunction" block>{{
            infoModal.type != 'Ajouter' ? "Modifier" : "Ajouter" }}</b-button>
        </div>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop id="ModalCnss" title="Resumé mensuel des Salaires">
      <b-row>
        <b-col>
          <label for="input_date_cnss">Date</label>
        </b-col>
        <b-col>
          <b-form-input type="month" id="input_date_cnss" v-model="monthCNSS"></b-form-input>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="printCNSS()">Imprimer</b-button>
        <b-button variant="danger" @click="cancel()">Annuler</b-button>
      </template>
    </b-modal>
  </div>
</template>



<script>
import { HTML5_FMT } from "moment";

export default {
  name: "Salaries-component",
  data() {
    var moment = require("moment");
    moment.locale('fr');
    return {
      monthCNSS: moment().format(HTML5_FMT.MONTH),
      //to_date: moment().format("YYYY[-W]ww"),
      from_date: moment().format("YYYY[-W]ww"),
      payments: [],
      employees: [],
      employee: "",
      fields: [
        {
          key: "id_emp_ci",
          label: "#",
          sortable: true,
        },
        {
          key: "Nom_emp",
          label: "Nom Complet",
          sortable: true,
        },
        {
          key: "date_pay",
          label: "Date",
          sortable: true,
        },
        {
          key: "total_h",
          label: "T.Jours de travail",
          sortable: true,
        },
        {
          key: "taux",
          label: "taux",
          sortable: true,
        },
        {
          key: "somme",
          label: "somme",
          sortable: true,
          tdClass: "text-right",
        },
        "actions",
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        type: "",
        content: {
          taux: 0,
          total_h: 0,
          date_pay: moment().format("YYYY[-W]ww"),
        },
      }, selected_mode: 'week',
      salary_mode: [
        { text: 'Semaine', value: "week" },
        { text: 'Quainzaine', value: 'fifteen' },
      ],

    };
  },
  computed: {
    total: function () {
      return this.payments.reduce((a, b) => a + b.taux * b.total_h, 0);
    },
  },
  methods: {
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.type = "";
      this.infoModal.content = {
        date_pay: this.from_date,
        taux: 0,
        total_h: 0,
      };
    },
    search() {
      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/pays/get_pays",
          {
            ldate: this.from_date,
            //rdate: this.to_date,
            Nom_emp: this.employee,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;

          this.payments = data.map((el, index) => {
            el.index = index;
            return el;
          });
          //this.isbusy = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },


    addSalary() {
      const axios = require("axios");
      var x = {
        Nom_emp: this.infoModal.content.Nom_emp,
        total_h: this.infoModal.content.total_h,
        date_pay: this.infoModal.content.date_pay,
        taux: this.infoModal.content.taux,
      };
      axios
        .post(this.$store.state.api_url + "/pays/add_pay", x, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          const moment = require("moment");

          console.log("Success :", response);
          //this.isbusy = false;
          x.id_pay = response.data.id;
          x.index = this.payments.length;
          x.id_emp_ci = response.data.id_emp_ci;
          // x.total_h = parseInt(x.total);
          x.date_pay = moment(x.date_pay, moment.HTML5_FMT.WEEK).format(
            "YYYY-MM-DD"
          );

          if (
            moment(x.date_pay).isSame(
              moment(this.from_date, moment.HTML5_FMT.WEEK)
            )
          ) {
            //console.log(this.infoModal.content);
            this.payments.splice(this.payments.length, 1, x);
          }
          //console.log(this.infoModal.content.type == "ٌRecette");

          this.$bvToast.toast("Ajouté avec succée", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          if (err.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else if (err.response.status === 412) {
            this.$bvToast.toast(
              "le salaire hebdomadaire de ce salarié est deja saisi",
              {
                title: "Erreur",
                variant: "danger",
                solid: true,
              }
            );
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        })
        .finally(() => this.$root.$emit("bv::hide::modal", this.infoModal.id));
    },
    printCNSS() {
      const pdfMake = require("pdfmake/build/pdfmake.min.js");
      const pdfFonts = require("pdfmake/build/vfs_fonts.js");
      const moment = require("moment");
      moment.locale("fr");
      const axios = require("axios");

      axios
        .post(
          this.$store.state.api_url + "/pays/get_cnss",
          {
            date: this.monthCNSS,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(async (response) => {
          var data = response.data;
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          var logo = document.getElementById("logo");
          var canvas = document.createElement("canvas");
          canvas.width = logo.width;
          canvas.height = logo.height;
          canvas.getContext("2d").drawImage(logo, 0, 0);
          var win = window.open(
            "",
            "_blank",
            "width=757,height=1000,menubar=no,status=no"
          );
          await console.log(data);
          data = data.map((el) => {
            el.total_h = el.totals_h.reduce(
              //(a, b) => (a + parseFloat(b) > 26 ? 26 : a + parseFloat(b)),
              (a, b) => a + parseFloat(b),
              0
            );

            el.salaire = el.totals_h.reduce(
              (a, b, c) =>
                //a + parseFloat(b) > 26
                //? a
                //:
                a + parseFloat(b) * parseFloat(el.taux[c]),
              0
            );

            return el;
          });
          var docDefinition = {
            pageSize: "A4",
            content: [
              {
                alignment: "justify",
                columns: [
                  /*  {
                    width: 80,
                    image: canvas.toDataURL("image/png"),
                  },
                  {
                    //width: "*",

                    //alignment: "right",
                    text: [
                      {
                        text: "Ain Salama",
                        bold: true,
                        fontSize: 25,
                        color: "#e6853c",
                      },
                      {
                        text: "\nSource Thermal",
                        fontSize: 16,
                        bold: true,
                        color: "#34ed59",
                      },
                      {
                        text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                        italics: true,
                      },
                    ],
                  },*/
                ],
              },
              {
                text: "Resume Mensuel des Salaires ",
                decoration: "underline",
                alignment: "center",
                fontSize: 20,
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                text:
                  moment(this.monthCNSS, moment.HTML5_FMT.MONTH).format(
                    "MMMM"
                  ) +
                  " / " +
                  moment(this.monthCNSS, moment.HTML5_FMT.MONTH).format("YYYY"),
                decoration: "underline",
                alignment: "center",
                fontSize: 20,
                bold: true,
                margin: [0, 5, 0, 20],
              },
              {
                table: {
                  heights: 30,
                  headerRows: 1,
                  widths: [20, "*", "*", "*"],
                  body: [
                    [
                      { text: "N", alignment: "right" },
                      "Nom et Prenom",
                      "Total des jours travaillés",
                      "Somme",
                    ],
                    ...data.map((el, index) => [
                      { text: index + 1, alignment: "right" },
                      el.Nom_emp,

                      { text: el.total_h, alignment: "right" },
                      { text: el.salaire, alignment: "right" },
                    ]),
                    [
                      { text: "", colSpan: 2, fillColor: "#AAAAAA" },
                      {},
                      {
                        text: "Total des Salaires",
                        alignment: "center",
                      },
                      {
                        text: data
                          .reduce((a, b) => a + b.salaire, 0)
                          .toFixed(2),
                        alignment: "right",
                      },
                    ],
                  ],
                },
              },

            ],
            defaultStyle: {
              columnGap: 25,
            },
          };
          pdfMake.createPdf(docDefinition).print({}, win);
        });
    },
    print_fiche() {
      var pdfMake = require("pdfmake/build/pdfmake.min.js");
      var pdfFonts = require("pdfmake/build/vfs_fonts.js");
      const moment = require("moment");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      var win = window.open(
        "",
        "_blank",
        "width=757,height=1000,menubar=no,status=no"
      );
      var docDefinition = {
        pageSize: "A4",
        content: [
          /* {
            alignment: "justify",
            columns: [
              {
                width: 80,
                image: canvas.toDataURL("image/png")
              },
              {
                //width: "*",

                //alignment: "right",
                text: [
                  {
                    text: "Ain Salama",
                    bold: true,
                    fontSize: 25,
                    color: "#e6853c"
                  },
                  {
                    text: "\nSource Thermal",
                    fontSize: 16,
                    bold: true,
                    color: "#34ed59"
                  },
                  {
                    text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                    italics: true
                  }
                ]
              }
            ]
          },*/
          {
            text: "Registre des salaires : ",
            decoration: "underline",
            alignment: "center",
            fontSize: 20,
            bold: true,
            margin: [0, 5],
          },
          {
            text:
              moment(this.from_date, moment.HTML5_FMT.WEEK).format(
                "DD/MM/YYYY"
              ) +
              " - " +
              moment(this.from_date, moment.HTML5_FMT.WEEK)
                .add(6, "d")
                .format("DD/MM/YYYY"),
            decoration: "underline",
            alignment: "center",
            fontSize: 20,
            bold: true,
            margin: [0, 20],
          },
          {
            table: {
              heights: 30,
              headerRows: 1,
              widths: [20, "auto", "auto", "auto", "auto", "auto", "*"],
              body: [
                [
                  { text: "N", alignment: "right" },
                  "Nom et Prenom",
                  "Semaine",
                  "T.jours travaillés",
                  "Taux",
                  "Somme",
                  "Signature",
                ],
                ...this.payments.map((el, index) => [
                  { text: index + 1, alignment: "right" },
                  el.Nom_emp,
                  {
                    text: moment(el.date_pay, "YYYY-MM-DD").week(),
                    alignment: "right",
                  },
                  { text: el.total_h, alignment: "right" },
                  { text: el.taux, alignment: "right" },
                  {
                    text: (el.total_h * el.taux).toFixed(2),
                    alignment: "right",
                  },
                  "",
                ]),
                [
                  { text: "", colSpan: 4, fillColor: "#AAAAAA" },
                  {},
                  {},
                  {},
                  {
                    text: "Total des Salaires",
                    alignment: "center",
                    colSpan: 2,
                  },
                  {},
                  { text: this.total.toFixed(2), alignment: "right" },
                ],
              ],
            },
          },

        ],
        defaultStyle: {
          columnGap: 25,
        },
      };
      pdfMake.createPdf(docDefinition).print({}, win);
    },
    prompt_Add() {
      this.infoModal.content.date_pay = this.from_date;
      this.infoModal.type = "Ajouter";
      this.$root.$emit("bv::show::modal", this.infoModal.id);
    },
    show_prompt_delete(row) {
      this.$bvModal
        .msgBoxConfirm("Merci de confirmer la suppression.", {
          title: "Confirmez SVP",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          if (value) {
            const axios = require("axios");

            axios
              .post(
                this.$store.state.api_url + "/pays/delete_pay",
                {
                  id_pay: row.item.id_pay,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                console.log("item", row.item);

                var index = this.payments.indexOf(row.item);
                this.payments.splice(index, 1);
                console.log("index", index);

                console.log("Success", response);
                this.$bvToast.toast("supprimée avec succée", {
                  title: `Succée`,
                  variant: "success",
                  solid: true,
                });
                //this.isbusy = false;
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  console.log("uNauthorized request");
                  console.log(err);
                  this.error();
                } else {
                  this.$bvToast.toast("Erreur", {
                    variant: "danger",
                    solid: true,
                  });
                  console.log("error : " + err);
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    show_prompt_update(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;

      Object.assign(this.infoModal.content, item);
      this.infoModal.content.date_pay = this.convert_to_date(
        this.infoModal.content.date_pay
      );
      this.infoModal.type = "Modifier";
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    updateSalary() {
      const axios = require("axios");
      var x = {
        id_pay: this.infoModal.content.id_pay,
        Nom_emp: this.infoModal.content.Nom_emp,
        total_h: parseInt(this.infoModal.content.total_h),
        date_pay: this.infoModal.content.date_pay,
        taux: this.infoModal.content.taux,
      };
      axios
        .post(this.$store.state.api_url + "/pays/update_pay", x, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log("Success :", response);
          //this.isbusy = false;
          this.$bvToast.toast("Modifié avec succée", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
          const moment = require("moment");
          this.infoModal.content.date_pay = moment(
            this.infoModal.content.date_pay,
            moment.HTML5_FMT.WEEK
          ).format("YYYY-MM-DD");
          this.payments.splice(
            this.infoModal.content.index,
            1,
            this.infoModal.content
          );

          // this.ops.splice(this.ops.length);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        })
        .finally(() => this.$root.$emit("bv::hide::modal", this.infoModal.id));
    },
    callFunction() {
      if (this.infoModal.type == "Ajouter") {
        console.log("launching add Operation");

        this.addSalary();
      } else {
        this.updateSalary();
        console.log("updating operation");
      }
    },
    print() { },
    convert_to_date(value) {
      const moment = require("moment");
      return moment(value, "YYYY-MM-DD").format(moment.HTML5_FMT.WEEK);
    },
    format_date(value) {
      const moment = require("moment");

      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    onDateChange(date) {
      let endDate = new Date(date[0]);
      endDate.setDate(endDate.getDate() + 13);
      this.selectedDate = [date[0], endDate];
    }
  },
  mounted() {
    const axios = require("axios");
    axios
      .post(
        this.$store.state.api_url + "/employees/get_employees",
        {
          ldate: this.from_date,
          rdate: this.to_date,
          Nom_emp: this.employee,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        this.employees = data.map((el, index) => {
          el.index = index;
          el.showname = el.id_emp_ci + " - " + el.Nom_complet;
          return el;
        });

        //this.isbusy = false;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log("uNauthorized request");
          console.log(err);
          this.error();
        } else {
          console.log("error : " + err);
        }
      });
  },
  watch:{
    selected_mode: function(value){
      if(value!="week")
      this.$router.push(`/salaries_quanz`)
    }
  }

};
</script>

<style scoped>
#elements {
  margin-top: 3%;
  width: 50%;
}

.table-responsive {
  background-color: white;
}
</style>