<template>
  <div id="app">
    <b-navbar type="dark" variant="info">
      <b-navbar-brand href="#">Jerry Accounting 2.0</b-navbar-brand>
      <b-navbar-nav class="ml-auto" v-if="this.$route.path!== '/Menu' && this.$route.path!== '/' ">
        <b-button variant="danger" @click="goToMenu">Retour</b-button>
      </b-navbar-nav>
    </b-navbar>
    <b-container fluid>
      <router-view  />
    </b-container>
  </div>
</template>

<script>
//import Login from "@/views/Login.vue";
import Router from '@/router/index.js'; 

export default {
  data() {
    return {
      showReturn: false
    };
  },
  components: {},
  watch: {
    $route: function() {
      // Check if given route is true, if it is then hide Nav.
      if (this.$route.path === "/Menu") {
        this.showReturn = false;
      } else {
        this.showReturn = true;
      }
    }
  },
  methods: {
    goToMenu: () => Router.push("Menu")
  }
};
</script>



<style>
</style>
