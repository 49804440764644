<template>
  <div id="elements" style="width:100%">
    <div id="research">
      <b-row>
        <b-col cols="12" lg="3">

          <div class="form-group">
            <label for="selectedYear">Année:</label>
            <b-form-select v-model="selectedYear" :options="years" id="selectedYear"></b-form-select>
          </div>

          <label for="ldate">Quanzaine : </label>
          <b-form-select v-model="selectedSpan" id="fifteenDaysPicker">
            <option v-for="option in dateSpans" :key="option.text" :value="option">
              {{ option.text }}
            </option>
          </b-form-select>
          <label for="ldate">Nom Complet</label>
          <v-select v-model="employee" :options="employees" :reduce="employee => employee.Nom_complet" label="showname">
            <div slot="no-options">désolé, aucune option correspondante</div>
          </v-select>
        </b-col>
        <b-col cols="12" lg="3">
          <!--<b-form-datalist id="motifs-list" :options="motifs"></b-form-datalist>-->
          <label>.</label>
          <div>
            <b-button block variant="success" @click="search">Rechercher</b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="3">
          <label for="ldate">Impression</label>
          <div>
            <b-button block variant="warning" @click="print_fiche">Fiche de Paie</b-button>
          </div>
          <label>.</label>
          <div>
            <b-button disabled block variant="info" v-b-modal.ModalCnss>Resumé Mensuel</b-button>
          </div>
        </b-col>
      </b-row>
      <br />
    </div>
    <div>
      <b-jumbotron>
        <b-row>
          <b-col>
            <b-alert show variant="success" class="pb-4" style="text-align:center">
              Registre des salaires
              <b-button style="float:right" variant="primary" @click="prompt_Add()">Ajouter</b-button>
              <div class="mt-3">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group button-variant="outline-primary" id="btn-radios-1" v-model="selected_mode"
                    :options="salary_mode" :aria-describedby="ariaDescribedby" name="radios-btn-default"
                    buttons></b-form-radio-group>
                </b-form-group>
              </div>
            </b-alert>
            <b-table head-variant="dark" foot-variant="null" :fields="fields" primary-key="id_emp_ci" :items="payments"
              sortBy="id_emp_ci" hover responsive>
              <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
              <template v-slot:cell(somme)="data">{{ (data.item.taux * data.item.total_h).toFixed(2) }}</template>
              <template v-slot:cell(date)="data">{{ formatQuainzaineForTable(data.item.ldate_pay, data.item.rdate_pay)
              }}</template>
              <template v-slot:cell(actions)="row">
                <b-dropdown variant="warning" id="dropdown-buttons" text="Options" class="m-2">
                  <b-dropdown-item-button
                    @click="show_prompt_update(row.item, row.index, $event.target)">Modifier</b-dropdown-item-button>
                  <b-dropdown-item-button @click="show_prompt_delete(row)">Suprimer</b-dropdown-item-button>
                </b-dropdown>
              </template>
              <template v-slot:custom-foot>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th variant="success" colspan="2">Total</b-th>
                  <b-th variant="success" colspan="2">{{ total.toFixed(2) }}</b-th>
                </b-tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-jumbotron>
    </div>
    <img id="logo" src="@/assets/logo.jpg" style="display:none" />
    <b-modal :id="infoModal.id" :title="infoModal.type" header-text-variant="dark"
      :header-bg-variant="infoModal.type != 'Ajouter' ? 'warning' : 'success'" @hide="resetInfoModal">
      <b-container fluid>
        <b-row class="mb-2">
          <b-col cols="3">employe</b-col>
          <b-col>

            <v-select v-model="infoModal.content.Nom_emp" :options="employees" :reduce="employee => employee.Nom_complet"
              label="showname" taggable
              :create-option="nom => ({ Nom_complet: nom, index: employees[employees.length - 1].id_emp + 1, showname: '[Nouvel employé va etre créé] ' + nom })"></v-select>

          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">T. Jours</b-col>
          <b-col>
            <b-input type="number" min="0" max="15" step="0.5" v-model.number="infoModal.content.total_h" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Taux</b-col>
          <b-col>
            <b-input type="number" min="0" v-model.number="infoModal.content.taux" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Quanzaine : </b-col>
          <b-col>
            <b-form-select v-model="infoModal.content.date_pay" id="fifteenDaysPicker">
              <option v-for="option in dateSpans" :key="option.text" :value="option">
                {{ option.text }}
              </option>
            </b-form-select>

          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button :variant="infoModal.type != 'Ajouter' ? 'warning' : 'success'" @click="callFunction" block>{{
            infoModal.type != 'Ajouter' ? "Modifier" : "Ajouter" }}</b-button>
        </div>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop id="ModalCnss" title="Resumé mensuel des Salaires">
      <b-row>
        <b-col>
          <label for="input_date_cnss">Date</label>
        </b-col>
        <b-col>
          <b-form-input type="month" id="input_date_cnss" v-model="monthCNSS"></b-form-input>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="printCNSS()">Imprimer</b-button>
        <b-button variant="danger" @click="cancel()">Annuler</b-button>
      </template>
    </b-modal>
  </div>
</template>



<script>
import { HTML5_FMT } from "moment";
import moment from 'moment';

export default {
  name: "Salaries-Quanzaine-component",
  data() {
    moment.locale('fr');
    return {
      monthCNSS: moment().format(HTML5_FMT.MONTH),
      //to_date: moment().format("YYYY[-W]ww"),
      from_date: moment().format("YYYY[-W]ww"),
      payments: [],
      employees: [],
      employee: "",
      fields: [
        {
          key: "id_emp_ci",
          label: "#",
          sortable: true,
        },
        {
          key: "Nom_emp",
          label: "Nom Complet",
          sortable: true,
        },
        {
          key: "ldate_pay",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            const ldate = moment.utc(item.ldate_pay);
            console.log("ldate: ", ldate);

            const rdate = moment.utc(item.rdate_pay);
            console.log("rdate: ", rdate);

            return ldate.format('DD/MM/YYYY') + ' - ' + rdate.format('DD/MM/YYYY');
          }
        },
        {
          key: "total_h",
          label: "T.Jours de travail",
          sortable: true,
        },
        {
          key: "taux",
          label: "taux",
          sortable: true,
        },
        {
          key: "somme",
          label: "somme",
          sortable: true,
          tdClass: "text-right",
        },
        "actions",
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        type: "",
        content: {
          taux: 0,
          total_h: 0,
          date_pay: 0,
        },
      }, selected_mode: 'fifteen',
      salary_mode: [
        { text: 'Semaine', value: "week" },
        { text: 'Quainzaine', value: 'fifteen' },
      ], selectedYear: new Date().getFullYear(), // Default to the current year
      selectedSpan: null,
      dateSpans: [],
      years: []

    };
  },
  computed: {
    total: function () {
      return this.payments.reduce((a, b) => a + b.taux * b.total_h, 0);
    },
  },
  methods: {
    populateYears() {
      const currentYear = new Date().getFullYear();
      for (let year = currentYear - 5; year <= currentYear + 5; year++) {
        this.years.push({ text: year.toString(), value: year });
      }
    },
    updateDateSpans() {
      this.dateSpans = []; // Clear existing date spans
      let index_month = 0;
      for (let month = 0; month < 12; month++) { // Loop for each month
        let firstDateOfMonth = moment([this.selectedYear, month, 1]);
        let middleDateOfMonth = moment([this.selectedYear, month, 15]);
        let lastDateOfMonth = moment([this.selectedYear, month]).endOf('month');

        // First span of the month: 1 - 15
        this.addDateSpan(++index_month , firstDateOfMonth, middleDateOfMonth);

        // Second span of the month: 16 - end of the month
        this.addDateSpan(++index_month + 1, middleDateOfMonth.add(1, 'days'), lastDateOfMonth);
      }

      // Find the span containing today's date and set as the selected span
      const today = moment();
      const foundSpan = this.dateSpans.find(span => today.isBetween(span.startDate, span.endDate, 'days', '[]'));
      this.selectedSpan = foundSpan;
    },
    addDateSpan(index, startDate, endDate) {
      this.dateSpans.push({
        text: index + " : " + startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY'),
        startDate: startDate, // Added for better date comparison
        endDate: endDate // Added for better date comparison
      });
    }
    ,
    getFirstMonday(year) {
      const jan1 = moment([year, 0, 1]);
      return moment([year, 0, (jan1.day() <= 1 ? 1 : 8) - jan1.day() + 1]);
    },
    selectCurrentSpan() {
      // Automatically select the corresponding half-month span if the current year is selected
      const today = moment();
      for (let i = 0; i < this.dateSpans.length; i++) {
        const span = this.dateSpans[i];

        // Extract start and end dates from the span's text
        const startDate = span.startDate
        const endDate = span.endDate

        if (today.isBetween(startDate, endDate, 'days', '[]')) {

          this.selectedSpan = span;
          this.infoModal.content.date_pay = span;
          break;
        }
      }
    }
    // ends here
    ,
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.type = "";
      this.infoModal.content = {
        date_pay: this.from_date,
        taux: 0,
        total_h: 0,
      };
    },
    search() {

      const startDate = this.selectedSpan.startDate.format('YYYY-MM-DD');
      const endDate = this.selectedSpan.endDate.format('YYYY-MM-DD');

      const axios = require("axios");
      axios
        .post(
          this.$store.state.api_url + "/pays_quanz/get_pays",
          {
            ldate_pay: startDate,
            rdate_pay: endDate,
            Nom_emp: this.employee,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;

          this.payments = data.map((el, index) => {
            el.index = index;
            return el;
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },


    addSalary() {
      const axios = require("axios");
      const startDate = this.selectedSpan.startDate.format('YYYY-MM-DD');
      const endDate = this.selectedSpan.endDate.format('YYYY-MM-DD');
      
      var x = {
        Nom_emp: this.infoModal.content.Nom_emp,
        total_h: this.infoModal.content.total_h,
        ldate_pay: startDate,
        rdate_pay: endDate,
        taux: this.infoModal.content.taux,
      };
      axios
        .post(this.$store.state.api_url + "/pays_quanz/add_pay", x, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
          // const moment = require("moment");

          // console.log("Success :", response);
          // //this.isbusy = false;
          // x.id_pay = response.data.id;
          // x.index = this.payments.length;
          // x.id_emp_ci = response.data.id_emp_ci;
          // x.total_h = parseInt(x.total);
          // x.date_pay = moment(x.date_pay, moment.HTML5_FMT.WEEK).format(
          //   "YYYY-MM-DD"
          // );

          // if (
          //   moment(x.date_pay).isSame(
          //     moment(this.from_date, moment.HTML5_FMT.WEEK)
          //   )
          // ) {
          //   //console.log(this.infoModal.content);
          //   this.payments.splice(this.payments.length, 1, x);
          // }
          //console.log(this.infoModal.content.type == "ٌRecette");

          this.$bvToast.toast("Ajouté avec succée", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });

          this.search()
        })
        .catch((err) => {
          if (err.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else if (err.response.status === 412) {
            this.$bvToast.toast(
              "le salaire hebdomadaire de ce salarié est deja saisi",
              {
                title: "Erreur",
                variant: "danger",
                solid: true,
              }
            );
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        })
        .finally(() => this.$root.$emit("bv::hide::modal", this.infoModal.id));
    },
    printCNSS() {
      const pdfMake = require("pdfmake/build/pdfmake.min.js");
      const pdfFonts = require("pdfmake/build/vfs_fonts.js");
      const moment = require("moment");
      moment.locale("fr");
      const axios = require("axios");

      axios
        .post(
          this.$store.state.api_url + "/pays_quanz/get_cnss",
          {
            date: this.monthCNSS,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(async (response) => {
          var data = response.data;
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          var logo = document.getElementById("logo");
          var canvas = document.createElement("canvas");
          canvas.width = logo.width;
          canvas.height = logo.height;
          canvas.getContext("2d").drawImage(logo, 0, 0);
          var win = window.open(
            "",
            "_blank",
            "width=757,height=1000,menubar=no,status=no"
          );
          await console.log(data);
          data = data.map((el) => {
            el.total_h = el.totals_h.reduce(
              //(a, b) => (a + parseFloat(b) > 26 ? 26 : a + parseFloat(b)),
              (a, b) => a + parseFloat(b),
              0
            );

            el.salaire = el.totals_h.reduce(
              (a, b, c) =>
                //a + parseFloat(b) > 26
                //? a
                //:
                a + parseFloat(b) * parseFloat(el.taux[c]),
              0
            );

            return el;
          });
          var docDefinition = {
            pageSize: "A4",
            content: [
              {
                alignment: "justify",
                columns: [
                  /*  {
                    width: 80,
                    image: canvas.toDataURL("image/png"),
                  },
                  {
                    //width: "*",

                    //alignment: "right",
                    text: [
                      {
                        text: "Ain Salama",
                        bold: true,
                        fontSize: 25,
                        color: "#e6853c",
                      },
                      {
                        text: "\nSource Thermal",
                        fontSize: 16,
                        bold: true,
                        color: "#34ed59",
                      },
                      {
                        text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                        italics: true,
                      },
                    ],
                  },*/
                ],
              },
              {
                text: "Resume Mensuel des Salaires ",
                decoration: "underline",
                alignment: "center",
                fontSize: 20,
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                text:
                  moment(this.monthCNSS, moment.HTML5_FMT.MONTH).format(
                    "MMMM"
                  ) +
                  " / " +
                  moment(this.monthCNSS, moment.HTML5_FMT.MONTH).format("YYYY"),
                decoration: "underline",
                alignment: "center",
                fontSize: 20,
                bold: true,
                margin: [0, 5, 0, 20],
              },
              {
                table: {
                  heights: 30,
                  headerRows: 1,
                  widths: [20, "*", "*", "*"],
                  body: [
                    [
                      { text: "N", alignment: "right" },
                      "Nom et Prenom",
                      "Total des jours travaillés",
                      "Somme",
                    ],
                    ...data.map((el, index) => [
                      { text: index + 1, alignment: "right" },
                      el.Nom_emp,

                      { text: el.total_h, alignment: "right" },
                      { text: el.salaire, alignment: "right" },
                    ]),
                    [
                      { text: "", colSpan: 2, fillColor: "#AAAAAA" },
                      {},
                      {
                        text: "Total des Salaires",
                        alignment: "center",
                      },
                      {
                        text: data
                          .reduce((a, b) => a + b.salaire, 0)
                          .toFixed(2),
                        alignment: "right",
                      },
                    ],
                  ],
                },
              },

            ],
            defaultStyle: {
              columnGap: 25,
            },
          };
          pdfMake.createPdf(docDefinition).print({}, win);
        });
    },
    print_fiche() {
      var pdfMake = require("pdfmake/build/pdfmake.min.js");
      var pdfFonts = require("pdfmake/build/vfs_fonts.js");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const datesp = this.dateSpans[this.selectedSpan];
      var win = window.open(
        "",
        "_blank",
        "width=757,height=1000,menubar=no,status=no"
      );
      var docDefinition = {
        pageSize: "A4",
        content: [
          /* {
            alignment: "justify",
            columns: [
              {
                width: 80,
                image: canvas.toDataURL("image/png")
              },
              {
                //width: "*",

                //alignment: "right",
                text: [
                  {
                    text: "Ain Salama",
                    bold: true,
                    fontSize: 25,
                    color: "#e6853c"
                  },
                  {
                    text: "\nSource Thermal",
                    fontSize: 16,
                    bold: true,
                    color: "#34ed59"
                  },
                  {
                    text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                    italics: true
                  }
                ]
              }
            ]
          },*/
          {
            text: "Registre des salaires : ",
            decoration: "underline",
            alignment: "center",
            fontSize: 20,
            bold: true,
            margin: [0, 5],
          },
          {
            text: datesp,
            decoration: "underline",
            alignment: "center",
            fontSize: 20,
            bold: true,
            margin: [0, 20],
          },
          {
            table: {
              heights: 30,
              headerRows: 1,
              widths: [20, "auto", "auto", "auto", "auto", "*"],
              body: [
                [
                  { text: "N", alignment: "right" },
                  "Nom et Prenom",
                  // "Semaine",
                  "T.jours travaillés",
                  "Taux",
                  "Somme",
                  "Signature",
                ],
                ...this.payments.map((el, index) => [
                  { text: index + 1, alignment: "right" },
                  el.Nom_emp,
                  // {
                  //   text: startDate.format("DD/MM/YYYY") + "\br" + endDate.format("DD/MM/YYYY"),
                  //   alignment: "left",
                  // },
                  { text: el.total_h, alignment: "right" },
                  { text: el.taux, alignment: "right" },
                  {
                    text: (el.total_h * el.taux).toFixed(2),
                    alignment: "right",
                  },
                  "",
                ]),
                [
                  { text: "", colSpan: 3, fillColor: "#AAAAAA" },
                  {},
                  {},
                  // {},
                  {
                    text: "Total des Salaires",
                    alignment: "center",
                    colSpan: 2,
                  },
                  {},
                  { text: this.total.toFixed(2), alignment: "right" },
                ],
              ],
            },
          },

        ],
        defaultStyle: {
          columnGap: 25,
        },
      };
      pdfMake.createPdf(docDefinition).print({}, win);
    },
    prompt_Add() {
      this.infoModal.content.date_pay = this.selectedSpan
      this.infoModal.type = "Ajouter";
      this.$root.$emit("bv::show::modal", this.infoModal.id);
    },
    show_prompt_delete(row) {
      this.$bvModal
        .msgBoxConfirm("Merci de confirmer la suppression.", {
          title: "Confirmez SVP",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          if (value) {
            const axios = require("axios");

            axios
              .post(
                this.$store.state.api_url + "/pays_quanz/delete_pay",
                {
                  id_pay: row.item.id_pay,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                console.log("item", row.item);

                var index = this.payments.indexOf(row.item);
                this.payments.splice(index, 1);
                console.log("index", index);

                console.log("Success", response);
                this.$bvToast.toast("supprimée avec succée", {
                  title: `Succée`,
                  variant: "success",
                  solid: true,
                });
                //this.isbusy = false;
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  console.log("uNauthorized request");
                  console.log(err);
                  this.error();
                } else {
                  this.$bvToast.toast("Erreur", {
                    variant: "danger",
                    solid: true,
                  });
                  console.log("error : " + err);
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    show_prompt_update(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;

      Object.assign(this.infoModal.content, item);
      this.infoModal.content.date_pay = this.selectedSpan
      this.infoModal.type = "Modifier";
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    updateSalary() {
      const axios = require("axios");
      const startDate = this.infoModal.content.date_pay.startDate;
      const endDate = this.infoModal.content.date_pay.endDate;

      var x = {
        id_pay: this.infoModal.content.id_pay,
        Nom_emp: this.infoModal.content.Nom_emp,
        total_h: parseInt(this.infoModal.content.total_h),
        ldate_pay: startDate,
        rdate_pay: endDate,
        taux: this.infoModal.content.taux,
      };
      axios
        .post(this.$store.state.api_url + "/pays_quanz/update_pay", x, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log("Success :", response);
          //this.isbusy = false;
          this.$bvToast.toast("Modifié avec succée", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
          this.search()

          // this.ops.splice(this.ops.length);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        })
        .finally(() => this.$root.$emit("bv::hide::modal", this.infoModal.id));
    },
    callFunction() {
      if (this.infoModal.type == "Ajouter") {
        console.log("launching add Operation");

        this.addSalary();
      } else {
        this.updateSalary();
        console.log("updating operation");
      }
    },
    print() { },
    convert_to_date(value) {
      const moment = require("moment");
      return moment(value, "YYYY-MM-DD").format(moment.HTML5_FMT.WEEK);
    },
    format_date(value) {
      const moment = require("moment");

      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    onDateChange(date) {
      let endDate = new Date(date[0]);
      endDate.setDate(endDate.getDate() + 13);
      this.selectedDate = [date[0], endDate];
    },
    formatQuainzaineForTable(ldate_pay, rdate_pay) {
      console.log("Input ldate_pay: " + ldate_pay);
      console.log("Input rdate_pay: " + rdate_pay);
      const ldate = moment.utc(ldate_pay);
      console.log("ldate: ", ldate);

      const rdate = moment.utc(rdate_pay);
      console.log("rdate: ", rdate);

      return ldate.format('DD/MM/YYYY') + ' - ' + rdate.format('DD/MM/YYYY');
    }
  },
  mounted() {
    const axios = require("axios");
    axios
      .post(
        this.$store.state.api_url + "/employees/get_employees",
        {
          ldate: this.from_date,
          rdate: this.to_date,
          Nom_emp: this.employee,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        this.employees = data.map((el, index) => {
          el.index = index;
          el.showname = el.id_emp_ci + " - " + el.Nom_complet;
          return el;
        });

        //this.isbusy = false;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log("uNauthorized request");
          console.log(err);
          this.error();
        } else {
          console.log("error : " + err);
        }
      });


    this.populateYears();
    this.updateDateSpans();

  },

  watch: {
    selected_mode: function (value) {
      if (value == "week")
        this.$router.push(`/salaries`)
    }
    , selectedYear: {
      handler(newYear) {
        this.updateDateSpans();

        // Automatically select the corresponding 15-day span if the current year is selected
        if (newYear === new Date().getFullYear()) {
          this.selectCurrentSpan();
        }
      },
      immediate: true // Trigger the handler immediately on component load
    }
  }

};
</script>

<style scoped>
#elements {
  margin-top: 3%;
  width: 50%;
}

.table-responsive {
  background-color: white;
}
</style>