<template>
  <div id="elements" style="width:100%">
    <div id="research">
      <b-row>
        <b-col cols="12" lg="3">
          <label for="ldate">Date de debut {{ from_date | moment }}</label>
          <b-form-datepicker
            today-button
            local="fr"
            label-today-button="Aujourd'hui"
            v-model="from_date"
          ></b-form-datepicker>
          <label for="ldate">Date de fin</label>
          <b-form-datepicker
            today-button
            local="fr"
            label-today-button="Aujourd'hui"
            v-model="to_date"
          ></b-form-datepicker>
        </b-col>
        <b-col cols="12" lg="3">
          <label for="ldate">Type</label>
          <v-select label="countryName" v-model="part" :options="parts">
            <div slot="no-options">désolé, aucune option correspondante</div>
          </v-select>

          <!--<b-form-datalist id="part-list" :options="parts"></b-form-datalist>
          <b-form-datalist id="motifs-list" :options="motifs"></b-form-datalist>-->
          <label for="ldate">Actions</label>
          <div>
            <b-button block variant="success" @click="search">Rechercher</b-button>
          </div>
        </b-col>
        <b-col lg="3" cols="12" offset-lg="3">
          <br class=".d-none .d-md-block .d-lg-none" />

          <table id="totales" class="table table-hover">
            <tr class="table-success">
              <th>Recettes</th>
              <td text-right>{{recettes_mnt.toFixed(2)}}</td>
            </tr>
            <tr class="table-danger">
              <th>Depenses</th>
              <td text-right>{{depenses_mnt.toFixed(2)}}</td>
            </tr>
            <tr class="table-info">
              <th>Solde</th>
              <td text-right>{{(recettes_mnt-depenses_mnt).toFixed(2)}}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-jumbotron>
        <b-row>
          <b-col cols="12" lg="6">
            <b-alert show variant="success" class="pb-4" style="text-align:center">
              Recettes
              <b-button
                style="float:right"
                variant="primary"
                @click="prompt_Add('Recette')"
              >Ajouter une recette</b-button>
            </b-alert>
            <b-table
              head-variant="dark"
              primary-key="id_op"
              :fields="fields"
              :items="recettes"
              hover
              small
              responsive
            >
              <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
              <template v-slot:cell(date_op)="data">{{ convert_to_date(data.value) }}</template>
              <template v-slot:cell(mnt)="data">{{ data.value.toFixed(2) }}</template>

              <template v-slot:cell(actions)="row">
                <b-dropdown variant="warning" id="dropdown-buttons" text="Options" class="m-2">
                  <b-dropdown-item-button @click="info(row.item, row.index, $event.target)">Modifier</b-dropdown-item-button>
                  <b-dropdown-item-button @click="show_prompt_delete(row)">Suprimer</b-dropdown-item-button>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12" lg="6">
            <b-alert show variant="danger" class="pb-4" style="text-align:center">
              Depenses
              <b-button
                style="float:right"
                variant="primary"
                @click="prompt_Add('Depense')"
              >Ajouter une Depenses</b-button>
            </b-alert>
            <b-table
              head-variant="dark"
              :fields="fields"
              primary-key="id_op"
              :items="depenses"
              hover
              small
              responsive
            >
              <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
              <template v-slot:cell(mnt)="data">{{ data.value.toFixed(2) }}</template>
              <template v-slot:cell(date_op)="data">{{ convert_to_date(data.value) }}</template>
              <template v-slot:cell(actions)="row">
                <b-dropdown variant="warning" id="dropdown-buttons" text="Options" class="m-2">
                  <b-dropdown-item-button @click="info(row.item, row.index, $event.target)">Modifier</b-dropdown-item-button>
                  <b-dropdown-item-button @click="show_prompt_delete(row)">Suprimer</b-dropdown-item-button>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-jumbotron>
      <b-modal
      no-close-on-backdrop
        :id="infoModal.id"
        :title="infoModal.type + '  ' + infoModal.content.type "
        header-text-variant="dark"
        :header-bg-variant="infoModal.type!='Ajouter'?'warning':'success'"
        @hide="resetInfoModal"
      >
        <b-container fluid>
          <b-row class="mb-2">
            <b-col cols="3">Utilisateur</b-col>
            <b-col>
             <v-select label="countryName" v-model="infoModal.content.motif_op" :options="motifs" taggable></v-select>

            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Type</b-col>
            <b-col>
              <v-select label="countryName" v-model="infoModal.content.part" :options="parts" taggable>

              </v-select>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Date</b-col>
            <b-col>
              <b-form-datepicker v-model="infoModal.content.date_op" />
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Montant</b-col>
            <b-col>
              <input class="form-control" type="number" step="0.01" value="0.00" v-model.number="infoModal.content.mnt" />
            </b-col>
          </b-row>
          <b-row class="mb-2" v-if="infoModal.type=='Ajouter'">
            <b-col>
              <b-form-checkbox
                v-model="infoModal.add_to_off"
                value="true"
                unchecked-value="false"
                switch
              >Faire une copie de l'entrée dans le Manager</b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              :variant="infoModal.type!='Ajouter'?'warning':'success'"
              @click="callFunction"
              block
            >{{infoModal.type!='Ajouter'?"Modifier":"Ajouter"}}</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>



<script>
export default {
  name: "Records-component",
  components: {},
  data() {
    return {
      parts: [],
      motifs: [],
      ops: [],
      part: "",
      fields: [
        "#",
        {
          key: "part",
          label: "Type",
          sortable: true,
        },
        {
          key: "motif_op",
          label: "Utilisateur",
          sortable: true,
        },
        {
          key: "date_op",
          label: "Date",
          sortable: true,
        },
        {
          key: "mnt",
          label: "Montant",
          sortable: true,
          tdClass: "text-right",
        },
        "actions",
      ],

      from_date: this.moment_date(),
      to_date: this.moment_date(),
      infoModal: {
        id: "info-modal",
        title: "",
        type: "",
        add_to_off: "false",
        content: { date_op: new Date() },
      },
    };
  },
  computed: {
    recettes: function () {
      return this.ops.filter((el) => el.type == "Recette");
    },
    depenses: function () {
      return this.ops.filter((el) => el.type == "Depense");
    },
    recettes_mnt: function () {
      return this.recettes.reduce((a, b) => a + b.mnt, 0);
    },
    depenses_mnt: function () {
      return this.depenses.reduce((a, b) => a + b.mnt, 0);
    },
  },
  mounted() {
    const axios = require("axios");

    axios
      .get(this.$store.state.api_url + "/operations/get_parts", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        const data = response.data;
        this.parts = data.map((el) => el.part);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log("uNauthorized request");
          console.log(err);
          this.error();
        } else {
          console.log("error : " + err);
        }
      });
    axios
      .get(this.$store.state.api_url + "/operations/get_motifs", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        const data = response.data;
        this.motifs = data.map((el) => el.motif_op);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log("uNauthorized request");
          console.log(err);
          this.error();
        } else {
          console.log("error : " + err);
        }
      });
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;

      Object.assign(this.infoModal.content, item);
      this.infoModal.type = "Modifier";
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.type = "";
      this.infoModal.add_to_off = "false";
      this.infoModal.content = { date_op: this.from_date };
    },

    search() {
      const axios = require("axios");

      //this.isbusy = true;
      axios
        .post(
          this.$store.state.api_url + "/operations/get_operations",
          {
            ldate: this.moment_date(this.from_date),
            rdate: this.moment_date(this.to_date),
            part: this.part,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          this.ops = data.map((el, index) => {
            el.index = index;
            return el;
          });

          //this.isbusy = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },
    convert_to_date(value) {
      const moment = require("moment");
      return moment(value).format("DD/MM/YYYY");
    },
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    moment_date(date) {
      var moment = require("moment");

      return moment(date).format("YYYY-MM-DD");
    },
    updateOperation() {
      const axios = require("axios");

      axios
        .post(
          this.$store.state.api_url + "/operations/update_operation",
          {
            id_op: this.infoModal.content.id_op,
            motif_op: this.infoModal.content.motif_op,
            date_op: this.infoModal.content.date_op,
            part: this.infoModal.content.part,
            mnt: this.infoModal.content.mnt,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log("Success :", response);
          //this.isbusy = false;
          this.$bvToast.toast("Modifié avec succée", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
          this.ops.splice(
            this.infoModal.content.index,
            1,
            this.infoModal.content
          );

          // this.ops.splice(this.ops.length);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        })
        .finally(() => this.$root.$emit("bv::hide::modal", this.infoModal.id));
    },
    show_prompt_delete(row) {
      this.$bvModal
        .msgBoxConfirm("Merci de confirmer la suppression.", {
          title: "Confirmez SVP",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          if (value) {
            const axios = require("axios");

            axios
              .post(
                this.$store.state.api_url + "/operations/delete_operation",
                {
                  id_op: row.item.id_op,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                console.log("item", row.item);

                var index = this.ops.indexOf(row.item);
                this.ops.splice(index, 1);
                console.log("index", index);

                console.log("Success", response);
                this.$bvToast.toast("supprimée avec succée", {
                  title: `Succée`,
                  variant: "success",
                  solid: true,
                });
                //this.isbusy = false;
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  console.log("uNauthorized request");
                  console.log(err);
                  this.error();
                } else {
                  this.$bvToast.toast("Erreur", {
                    variant: "danger",
                    solid: true,
                  });
                  console.log("error : " + err);
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    prompt_Add(type) {
      this.infoModal.content.date_op = this.from_date;
      this.infoModal.content.type = type;
      this.infoModal.type = "Ajouter";

      this.$root.$emit("bv::show::modal", this.infoModal.id);
    },
    AddOprtation() {
      var x = {
        motif_op: this.infoModal.content.motif_op,
        type: this.infoModal.content.type,
        date_op: this.infoModal.content.date_op,
        part: this.infoModal.content.part,
        mnt: this.infoModal.content.mnt,
      };
      const axios = require("axios");

      axios
        .post(this.$store.state.api_url + "/operations/add_operation", x, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log("Success :", response);
          //this.isbusy = false;
          if (this.infoModal.add_to_off == "true")
            axios.post(
              this.$store.state.api_url + "/operations/add_operation_off",
              x,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            );
          this.infoModal.content.id_op = response.data.id;
          this.infoModal.content.index = this.ops.length;

          var moment = require("moment");

          if (
            moment(x.date_op).isBetween(
              this.from_date,
              this.to_date,
              undefined,
              "[]"
            )
          ) {
            this.ops.splice(this.ops.length, 1, this.infoModal.content);
          }
          //console.log(this.infoModal.content.type);
          //console.log(this.infoModal.content.type == "ٌRecette");

          this.$bvToast.toast("Ajouté avec succée", {
            title: `Succée`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
            this.$bvToast.toast("Erreur", {
              variant: "danger",
              solid: true,
            });
          }
        })
        .finally(() => this.$root.$emit("bv::hide::modal", this.infoModal.id));
    },
    callFunction() {
      if (this.infoModal.type == "Ajouter") {
        console.log("launching add Operation");

        this.AddOprtation();
      } else {
        this.updateOperation();
        console.log("updating operation");
      }
    },
  },
  filters: {
    moment: function (date) {
      // console.log(date)
      var moment = require("moment");

      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
#elements {
  margin-top: 3%;
  width: 50%;
}
.table-responsive {
  background-color: white;
}
</style>