<template>
  <div class="menu-wrapper">
    <div class="d-flex justify-content-around">
      <div class="bg-light" >
        <router-link to="/records">
          <figure class="figure">
            <img src="@/assets/caisse.png" class="figure-img img-fluid rounded" />
            <figcaption class="figure-caption text-center">
              Enregistrement
              <br />Modification
              <br />Consultation
            </figcaption>
          </figure>
        </router-link>
      </div>
      <div class="bg-light">
        <router-link to="/records_printing">
          <figure class="figure">
            <img src="@/assets/imprimante.png" class="figure-img img-fluid rounded" />
            <figcaption class="figure-caption text-center">Imrpression</figcaption>
          </figure>
        </router-link>
      </div>
      <div class="bg-light">
        <router-link to="/remises">
          <figure class="figure">
            <img src="@/assets/reduction.jpg" class="figure-img img-fluid rounded" />
            <figcaption class="figure-caption text-center">Remises</figcaption>
          </figure>
        </router-link>
      </div>
      <div class="bg-light">
        <router-link to="/salaries">
          <figure class="figure">
            <img src="@/assets/cheque.png" class="figure-img img-fluid rounded" />
            <figcaption class="figure-caption text-center">Registre des Salaires</figcaption>
          </figure>
        </router-link>
      </div>
      <div class="bg-light">
        <router-link to="/details">
          <figure class="figure">
            <img src="@/assets/magnifier.png" class="figure-img img-fluid rounded" />
            <figcaption class="figure-caption text-center">Details des caisses</figcaption>
          </figure>
        </router-link>
      </div>
      <div class="bg-light">
        <router-link to="/hr">
          <figure class="figure">
            <img src="@/assets/employee.png" class="figure-img img-fluid rounded" />
            <figcaption class="figure-caption text-center">Ressources Humaines</figcaption>
          </figure>
        </router-link>
      </div>
    </div>
    <!--
    <br />
    <div class="d-flex justify-content-center">
      <div class="bg-light" @click="closePage">
        <figure class="figure">
          <img src="@/assets/close.png" class="figure-img img-fluid rounded" />
          <figcaption class="figure-caption text-center">Quitter</figcaption>
        </figure>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "Menu-component",
  methods: {
    
  }
};
</script>

<style scoped>
.menu-wrapper {
  background: #fff;
  width: 80%;
  margin: 8% auto;
}
</style>