<template>
  <div id="elements" style="width:100%">
    <div id="research">
      <b-row>
        <b-col cols="12" lg="3">
          <label for="ldate">Date de debut</label>
          <b-form-datepicker
            today-button
            local="fr"
            label-today-button="Aujourd'hui"
            v-model="from_date"
          ></b-form-datepicker>
          <label for="ldate">Date de fin</label>
          <b-form-datepicker
            today-button
            local="fr"
            label-today-button="Aujourd'hui"
            v-model="to_date"
          ></b-form-datepicker>
        </b-col>
        <b-col cols="12" lg="3">
          <label for="ldate">Type</label>
          <v-select label="countryName" v-model="part" :options="parts">
                        <div slot="no-options">désolé, aucune option correspondante</div>

          </v-select>

          <!--<b-form-datalist id="motifs-list" :options="motifs"></b-form-datalist>-->
          <b-form-group label="type de recette">
            <b-form-radio-group
              class="col-12"
              v-model="selected_radio"
              :options="options_radio"
              buttons
              button-variant="outline-primary"
              name="radios-btn-default"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="3">
          <label for="ldate">Actions</label>
          <div>
            <b-button block variant="success" @click="search">Rechercher</b-button>
          </div>
          <label>.</label>
          <div>
            <b-button block variant="info" @click="print">Imprimer</b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="3">
          <label>.</label>
          <div>
            <b-button block variant="warning" @click="recap_printing">Recapitulation</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-jumbotron>
        <b-row>
          <b-col>
            <b-table
              foot-variant="null"
              :fields="fields"
              primary-key="id_op"
              :items="ops"
              hover
              responsive
              no-border-collapse
            >
              <template v-slot:thead-top>
                <b-tr>
                  <b-th colspan="4">
                    <span class="sr-only"></span>
                  </b-th>
                  <b-th colspan="2" class="text-center" variant="dark">Montant</b-th>
                </b-tr>
              </template>
              <template v-slot:head()="data">
                <span>{{ data.label.toUpperCase() }}</span>
              </template>
              <template v-slot:cell(#)="data">{{ data.index + 1 }}</template>
              <template v-slot:cell(dep)="data">
                <b>{{ data.value==0? "": parseFloat(data.value).toFixed(2)}}</b>
              </template>
              <template v-slot:cell(rec)="data">
                <b>{{ data.value==0? "": parseFloat(data.value).toFixed(2)}}</b>
              </template>

              <template v-slot:cell(date_op)="data">{{ convert_to_date(data.value) }}</template>

              <template v-slot:custom-foot>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th variant="success">Recettes</b-th>
                  <b-th
                    variant="success"
                    colspan="2"
                    style="text-align:right"
                  >{{recettes_mnt.toFixed(2)}}</b-th>
                </b-tr>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th variant="danger">Depenses</b-th>
                  <b-th
                    variant="danger"
                    colspan="2"
                    style="text-align:right"
                  >{{depenses_mnt.toFixed(2)}}</b-th>
                </b-tr>
                <b-tr>
                  <b-th colspan="3"></b-th>
                  <b-th variant="info">Solde</b-th>
                  <b-th
                    variant="info"
                    style="text-align:right"
                    colspan="2"
                  >{{(recettes_mnt-depenses_mnt).toFixed(2)}}</b-th>
                </b-tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-jumbotron>
    </div>
    <img id="logo" src="@/assets/logo.jpg" style="display:none" />
  </div>
</template>



<script>
export default {
  data() {
    return {
      from_date: this.moment_date(),
      to_date: this.moment_date(),
      part: "",
      parts: [],
      ops: [],
      selected_radio: "",
      options_radio: [
        { text: "Recettes", value: "Recette" },
        { text: "les deux", value: "" },
        { text: "Depenses", value: "Depense" },
      ],
      fields: [
        "#",
        {
          key: "part",
          label: "Type",
          sortable: true,
        },
        {
          key: "motif_op",
          label: "Utilisateur",
          sortable: true,
        },
        {
          key: "date_op",
          label: "Date",
          sortable: true,
        },

        {
          key: "dep",
          label: "Depenses",
          sortable: true,
          tdClass: "text-right",
          variant: "danger",
        },
        {
          key: "rec",
          label: "Recettes",
          sortable: true,
          tdClass: "text-right",
          variant: "success",
        },
      ],
    };
  },
  methods: {
    moment_date: function (date) {
      var moment = require("moment");
      return moment(date).format("YYYY-MM-DD");
    },
    print_date: function (date) {
      var moment = require("moment");
      return moment(date).format("DD/MM/YYYY");
    },
    search: function search() {
      const axios = require("axios");

      axios
        .post(
          this.$store.state.api_url + "/operations/get_operations",
          {
            ldate: this.moment_date(this.from_date),
            rdate: this.moment_date(this.to_date),
            part: this.part,
            type: this.selected_radio,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          this.ops = data.map((el, index) => {
            el.index = index;
            if (el.type == "Depense") {
              el.dep = el.mnt;
            } else {
              el.rec = el.mnt;
            }
            return el;
          });

          //this.isbusy = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },
    recap_printing: function () {
      const axios = require("axios");

      axios
        .post(
          this.$store.state.api_url + "/operations/get_recap_noff",
          {
            ldate: this.moment_date(this.from_date),
            rdate: this.moment_date(this.to_date),
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          var rct_mnt = data
            .filter((el) => el.type == "Recette")
            .reduce((a, b) => a + b.mnt, 0);
          var dps_mnt = data
            .filter((el) => el.type == "Depense")
            .reduce((a, b) => a + b.mnt, 0);

          var pdfMake = require("pdfmake/build/pdfmake.min.js");
          var pdfFonts = require("pdfmake/build/vfs_fonts.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          //var myWindow = window.open("", "", "fullscreen=on");
          var win = window.open(
            "",
            "_blank",
            "width=757,height=1000,menubar=no,status=no"
          );
          var logo = document.getElementById("logo");
          var canvas = document.createElement("canvas");
          canvas.width = logo.width;
          canvas.height = logo.height;
          canvas.getContext("2d").drawImage(logo, 0, 0);

          var docDefinition = {
            content: [
              /* {
            alignment: "justify",
            columns: [
              {
                width: 80,
                image: canvas.toDataURL("image/png")
              },
              {
                //width: "*",

                //alignment: "right",
                text: [
                  {
                    text: "Ain Salama",
                    bold: true,
                    fontSize: 25,
                    color: "#e6853c"
                  },
                  {
                    text: "\nSource Thermal",
                    fontSize: 16,
                    bold: true,
                    color: "#34ed59"
                  },
                  {
                    text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                    italics: true
                  }
                ]
              }
            ]
          },*/
              {
                text:
                  "Recapitulation : " +
                  this.print_date(this.from_date) +
                  " - " +
                  this.print_date(this.to_date),
                decoration: "underline",
                alignment: "center",
                fontSize: 20,
                bold: true,
                margin: [0, 20],
              },
              {
                table: {
                  headerRows: 2,
                  widths: [30, "*", "*", "*", "*"],
                  body: [
                    [
                      { text: "", colSpan: 3 },
                      {},
                      {},
                      { text: "Montant", alignment: "center", colSpan: 2 },
                      {},
                    ],
                    [
                      { text: "N", alignment: "center" },
                      "Type",
                      "Utilisateur",
                      "Depenses",
                      "Recettes",
                    ],
                    ...data.map((el, index) => [
                      { text: index + 1, alignment: "right" },
                      el.part,
                      el.motif_op,
                      {
                        text: el.type != "Recette" ? el.mnt.toFixed(2) : "",
                        alignment: "right",
                      },
                      {
                        text: el.type == "Recette" ? el.mnt.toFixed(2) : "",
                        alignment: "right",
                      },
                    ]),
                    [
                      { text: "", colSpan: 4, fillColor: "#575550" },
                      {},
                      {},
                      {},
                      {},
                    ],
                    [
                      { text: "", colSpan: 2 },
                      {},
                      {},
                      {
                        text: "Total des Recettes ",
                        alignment: "left",
                        colSpan: 1,
                      },
                      {
                        text: rct_mnt.toFixed(2),
                        alignment: "right",
                      },
                    ],
                    [
                      { text: "", colSpan: 2 },
                      {},
                      {},
                      {
                        text: "Total des Depenses",
                        alignment: "left",
                        colSpan: 1,
                      },
                      {
                        text: dps_mnt.toFixed(2),
                        alignment: "right",
                      },
                    ],
                    [
                      { text: "", colSpan: 2 },
                      {},
                      {},
                      { text: "Solde", alignment: "left", colSpan: 1 },
                      {
                        text: (rct_mnt - dps_mnt).toFixed(2),
                        alignment: "right",
                      },
                    ],
                  ],
                },
              },
             /* {
                text: {
                  text: "Validation",
                  decoration: "underline",
                  alignment: "right",
                },
                margin: [35, 20],
              },*/
            ],
            defaultStyle: {
              columnGap: 25,
            },
          };
          pdfMake.createPdf(docDefinition).open({}, win);

          //this.isbusy = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("uNauthorized request");
            console.log(err);
            this.error();
          } else {
            console.log("error : " + err);
          }
        });
    },
    print: function () {
      var pdfMake = require("pdfmake/build/pdfmake.min.js");
      var pdfFonts = require("pdfmake/build/vfs_fonts.js");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      //var myWindow = window.open("", "", "fullscreen=on");
      var win = window.open(
        "",
        "_blank",
        "width=757,height=1000,menubar=no,status=no"
      );
      var logo = document.getElementById("logo");
      var canvas = document.createElement("canvas");
      canvas.width = logo.width;
      canvas.height = logo.height;
      canvas.getContext("2d").drawImage(logo, 0, 0);

      var docDefinition = {
        content: [
          /* {
            alignment: "justify",
            columns: [
              {
                width: 80,
                image: canvas.toDataURL("image/png")
              },
              {
                //width: "*",

                //alignment: "right",
                text: [
                  {
                    text: "Ain Salama",
                    bold: true,
                    fontSize: 25,
                    color: "#e6853c"
                  },
                  {
                    text: "\nSource Thermal",
                    fontSize: 16,
                    bold: true,
                    color: "#34ed59"
                  },
                  {
                    text: "\n\n52 Avenue Allal ben Abdelah Meknes",
                    italics: true
                  }
                ]
              }
            ]
          },*/
          {
            text:
              "Recapitulation : " +
              this.print_date(this.from_date) +
              " - " +
              this.print_date(this.to_date),
            decoration: "underline",
            alignment: "center",
            fontSize: 20,
            bold: true,
            margin: [0, 20],
          },
          {
            table: {
              headerRows: 2,
              widths: [30, "*", "*", "*", "*", "*"],
              body: [
                [
                  { text: "", colSpan: 4 },
                  {},
                  {},
                  {},
                  { text: "Montant", alignment: "center", colSpan: 2 },
                  {},
                ],
                [
                  { text: "N", alignment: "center" },
                  "Type",
                  "Utilisateur",
                  "Date",
                  "Depenses",
                  "Recettes",
                ],
                ...this.ops.map((el, index) => [
                  { text: index + 1, alignment: "right" },
                  el.part,
                  el.motif_op,
                  this.print_date(el.date_op),
                  {
                    text: el.type != "Recette" ? el.mnt.toFixed(2) : "",
                    alignment: "right",
                  },
                  {
                    text: el.type == "Recette" ? el.mnt.toFixed(2) : "",
                    alignment: "right",
                  },
                ]),
                [
                  { text: "", colSpan: 5, fillColor: "#575550" },
                  {},
                  {},
                  {},
                  {},
                  {},
                ],
                [
                  { text: "", colSpan: 2 },
                  {},
                  {},
                  {
                    text: "Total des Recettes ",
                    alignment: "left",
                    colSpan: 2,
                  },
                  {},
                  { text: this.recettes_mnt.toFixed(2), alignment: "right" },
                ],
                [
                  { text: "", colSpan: 2 },
                  {},
                  {},
                  {
                    text: "Total des Depenses",
                    alignment: "left",
                    colSpan: 2,
                  },
                  {},
                  { text: this.depenses_mnt.toFixed(2), alignment: "right" },
                ],
                [
                  { text: "", colSpan: 2 },
                  {},
                  {},
                  { text: "Solde", alignment: "left", colSpan: 2 },
                  {},
                  {
                    text: (this.recettes_mnt - this.depenses_mnt).toFixed(2),
                    alignment: "right",
                  },
                ],
              ],
            },
          },
         /* {
            text: {
              text: "Validation",
              decoration: "underline",
              alignment: "right",
            },
            margin: [35, 20],
          },*/
        ],
        defaultStyle: {
          columnGap: 25,
        },
      };
      pdfMake.createPdf(docDefinition).open({}, win);
    },
    error() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    convert_to_date(value) {
      const moment = require("moment");
      return moment(value).format("DD/MM/YYYY");
    },
  },
  computed: {
    recettes: function () {
      return this.ops.filter((el) => el.type == "Recette");
    },
    depenses: function () {
      return this.ops.filter((el) => el.type == "Depense");
    },
    recettes_mnt: function () {
      return this.recettes.reduce((a, b) => a + b.mnt, 0);
    },
    depenses_mnt: function () {
      return this.depenses.reduce((a, b) => a + b.mnt, 0);
    },
  },
  mounted() {
    const axios = require("axios");

    axios
      .get(this.$store.state.api_url + "/operations/get_parts", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        const data = response.data;
        this.parts = data.map((el) => el.part);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log("uNauthorized request");
          console.log(err);
          this.error();
        } else {
          console.log("error : " + err);
        }
      });
  },
};
</script>

<style scoped>
#elements {
  margin-top: 3%;
  width: 50%;
}
.table-responsive {
  background-color: white;
}
.myTable thead {
  background-color: green;
}
</style>